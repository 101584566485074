// export const config = {
//   apiUrl: 'http://localhost:3012'
// };


export const config = {
  apiUrl: 'https://api.magopa.io'
};


