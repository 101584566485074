import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, Observable } from 'rxjs';
import { catchError, mapTo, tap } from 'rxjs/operators';
import { config } from './../../config';
import { Tokens } from '../models/tokens';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly JWT_TOKEN = 'JWT_TOKEN';
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
  private loggedUser: string;

  constructor(private http: HttpClient) {}


async getAdd() {
  return await this.http.get(`https://api.ipify.org?format=json`).toPromise().then(res => {
      return res
    })
    .catch(err => {
      console.log(err)
      return {data:'ERROR', error:true,res:err}
    });
}


  login(user: { username: string, password: string, ip: any }): Observable<boolean> {
    // return this.http.post<any>(`${config.apiUrl}/api/v1/auth/login`, user)
    // return this.http.post<any>(`${config.apiUrl}/login`, user)
    return this.http.post<any>(`${config.apiUrl}/api/v1/auth/login`, user)
      .pipe(
        tap(tokens => this.doLoginUser(user.username, tokens)),
        mapTo(true),
        catchError(error => {

          var errorMessage = error.error.text
          console.log(errorMessage)
          alert(error.error.message);
          return of(false);
        }));
  }

  logout() {
    
    console.log('loggin out')
    return this.http.post<any>(`${config.apiUrl}/api/v1/auth/logout`, {
      'refreshToken': this.getRefreshToken()
    }).pipe(
      tap(() => this.doLogoutUser()),
      mapTo(true),
      catchError(error => {
        console.log(error)
        // alert(error.error.message);
        return of(false);
      }));
  }

  isLoggedIn() {
    return !!this.getJwtToken();
  }

  refreshToken() {
    return this.http.post<any>(`${config.apiUrl}/api/v1/auth/refresh`, {
      'refreshToken': this.getRefreshToken()
    }).pipe(tap((tokens: Tokens) => {
      this.storeJwtToken(tokens.jwt);
    }));
  }

  getJwtToken() {
    return localStorage.getItem(this.JWT_TOKEN);
  }

  private doLoginUser(username: string, tokens: Tokens) {

    if(tokens.jwt){
      this.loggedUser = username;
      this.storeTokens(tokens);
    }else{
      var error = JSON.stringify(tokens)
      // alert (error)

    }

  }

  private doLogoutUser() {
    this.loggedUser = null!;
    this.removeTokens();
  }

  private getRefreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  private storeJwtToken(jwt: string) {
    localStorage.setItem(this.JWT_TOKEN, jwt);
  }

  private storeTokens(tokens: Tokens) {
    localStorage.setItem(this.JWT_TOKEN, tokens.jwt);
    localStorage.setItem(this.REFRESH_TOKEN, tokens.refreshToken);
  }

  private removeTokens() {
    localStorage.removeItem(this.JWT_TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
  }
}
