import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: any;
  add:any
  constructor(
      private authService: AuthService, 
      private formBuilder: FormBuilder,
       private router: Router) { }

  ngOnInit() {
    // this.getAdd()
    this.loginForm = this.formBuilder.group({
      username: [''],
      password: ['']
    });
  }

  get f() { return this.loginForm.controls; }


  async getAdd(  ){
 this.add = await this.authService.getAdd()

  }



  login() {
    this.authService.login(
      {
        username: this.f.username.value,
        password: this.f.password.value,
        ip:'0.0.0.0'
      }
        // ip:this.add.ip

      // {
      //   username: this.f.username.value,
      //   password: this.f.password.value,
      //   ip:this.add.ip
      // }
    )
    .subscribe(success => {
      if (success) {
        this.router.navigate(['/dashboard']);
      }
    });
  }

}
